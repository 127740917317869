import React from 'react';
import styled from 'styled-components';
import { graphql, Link } from 'gatsby';
import { MDXProvider } from '@mdx-js/react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import Img from 'gatsby-image';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import MainNavigation from '../components/MainNavigation';
import MainFooter from '../components/MainFooter';
import { Wrapper, Heading } from '../components/Home/common';
import SiteContainer from '../components/SiteContainer';
import GenericIcon from '../components/GenericIcon';
import { ReactComponent as ChevronIcon } from '../svgs/icons/chevron-right.svg';
import TabletNavigation from '../components/Home/TabletNavigation';

const PageWrapper = styled(Wrapper)`
	.hostjane-404__holder {
		max-width: 370px;
		margin-left: auto;
		margin-right: auto;
		@media screen and (min-width: ${props =>
				props.theme.siteApp.breakpoints.tablet}px) {
			max-width: 450px;
		}
		@media screen and (min-width: ${props =>
				props.theme.siteApp.breakpoints.desktop}px) {
			max-width: 560px;
		}
	}

	${Heading} {
		color: #111;
		padding: 0;
		font-size: 32px;
		line-height: 1.125;
		font-weight: 700;

		@media screen and (min-width: ${props =>
				props.theme.siteApp.breakpoints.tablet}px) {
			font-size: 40px;
			line-height: 1.1;
		}
		@media screen and (min-width: ${props =>
				props.theme.siteApp.breakpoints.desktop}px) {
			font-size: 48px;
			line-height: 1.08365;
			letter-spacing: -0.003em;
		}
	}

	.hostjane-404__text {
		text-align: center;
		margin-bottom: 35px;
		color: #444;
		&:last-child {
			margin-bottom: 0;
		}
		p {
			margin-top: 0;
			&:only-child {
				margin: 0;
			}
		}
		.hostjane-404__icon {
			height: 0.5em;
			width: 0.5em;
		}
	}
	a {
		color: #0057d8;
		text-decoration: none;
		&:hover {
			color: #0057d8;
			text-decoration: underline;
		}
		display: inline-flex;
		align-items: center;
		.hostjane-404__icon {
			margin-left: 0.4em;
			position: relative;
			top: 1px;
			z-index: -1;
		}
	}

	.hostjane-404__mascot {
		display: block;
		max-width: 300px;
		margin: 0 auto 35px;
	}
`;

function Chevron() {
	return (
		<GenericIcon className="hostjane-404__icon">
			<ChevronIcon />
		</GenericIcon>
	);
}

function NotFoundPage({ data, ...props }) {
	return (
		<Layout {...props} hasFooter={false} fullWidth>
			<SEO title={data.page.frontmatter.title} />
			<MainNavigation />
			<TabletNavigation />
			<PageWrapper className="hostjane-404">
				<SiteContainer>
					<div className="hostjane-404__holder">
						<Heading>{data.page.frontmatter.heading}</Heading>
						<div className="hostjane-404__text">
							<MDXProvider components={{ Chevron }}>
								<MDXRenderer>{data.page.body}</MDXRenderer>
							</MDXProvider>
						</div>
						<a
							href={data.page.frontmatter.mascotLink}
							className="hostjane-404__mascot"
						>
							<Img
								fluid={
									data.page.frontmatter.mascot.childImageSharp
										.fluid
								}
								style={{ zIndex: -1 }}
							/>
						</a>
						<div className="hostjane-404__text">
							Or see{' '}
							<Link to="/sitemap/">
								the Sitemap <Chevron />
							</Link>
						</div>
					</div>
				</SiteContainer>
			</PageWrapper>
			<MainFooter />
		</Layout>
	);
}

export default NotFoundPage;

export const pageQuery = graphql`
	query Jane404 {
		page: mdx(fileAbsolutePath: { glob: "**/site-data/404/content.mdx" }) {
			frontmatter {
				title
				heading
				mascot {
					childImageSharp {
						fluid(maxWidth: 600, traceSVG: { color: "#0057d8" }) {
							...GatsbyImageSharpFluid_withWebp_tracedSVG
						}
					}
				}
				mascotLink
			}
			body
		}
	}
`;
